var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page-wrapper"},[(_vm.cv_job_analysis.cv_loading)?[_c('div',{staticClass:"d-flex align-center justify-center",staticStyle:{"height":"100%"}},[_c('v-card',{staticClass:"d-flex align-center justify-center",attrs:{"height":"700","width":"700","elevation":"0"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary","size":"70","width":"9"}})],1)],1)]:[_c('v-row',{staticClass:"align-center upskill-score-section"},[_c('v-col',{attrs:{"cols":"12","md":"4","lg":"6"}},[_c('v-btn',{staticClass:"px-12 elevation-0 base-btn paste-btn",on:{"click":_vm.match_again}},[_vm._v(" Match Again ")])],1),_c('v-col',{attrs:{"cols":"12","md":"8","lg":"6"}},[_c('div',{staticClass:"\n            matching-score-block\n            ms-auto\n            d-flex\n            align-center\n            flex-column flex-md-row\n          "},[_c('div',{staticClass:"d-flex align-center flex-column flex-md-row score-card"},[_c('p',{staticClass:"mb-md-0 mr-md-4 text-no-wrap font-weight-bold"},[_vm._v(" Matching score "),(_vm.cv_job_analysis.compare_gap_loading)?[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"success"}})]:[_vm._v(" "+_vm._s(_vm.format_overall_score(_vm.compare_gap_score))+"% ")]],2),_c('v-progress-linear',{staticClass:"rounded",attrs:{"value":_vm.format_overall_score(_vm.compare_gap_score),"color":"#29CC39","height":"12"}})],1),_c('v-btn',{staticClass:"base-btn upskill-btn ms-md-n3 elevation-0 text-capitalize",attrs:{"disabled":_vm.cv_job_analysis.cv_loading ||
              _vm.cv_job_analysis.job_loading ||
              _vm.cv_job_analysis.compare_gap_loading ||
              !_vm.compare_gap_list},on:{"click":_vm.open_upskill_dialog}},[_vm._v(" Upskill ")])],1)])],1),(false)?_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 base-body-text font-weight-bold black--text mr-3"},[_vm._v("Job Title:")]),(_vm.cv_job_analysis.job_loading)?[_c('v-skeleton-loader',{staticClass:"flex-grow-1",attrs:{"type":"text"}})]:[_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(_vm.cv_job_analysis.job_response.job_title)+" ")])]],2)]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"mb-0 base-body-text font-weight-bold black--text mr-3"},[_vm._v("Industry:")]),(_vm.cv_job_analysis.job_loading)?[_c('v-skeleton-loader',{staticClass:"flex-grow-1",attrs:{"type":"text"}})]:[_c('p',{staticClass:"mb-0 base-body-text"},[_vm._v(" "+_vm._s(_vm.cv_job_analysis.job_response.sector)+" ")])]],2)])],1):_vm._e(),_c('SkillsGroup',{attrs:{"active_skill_group":_vm.active_skill_group},on:{"update_skill_group":_vm.update_skill_group}}),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":_vm.expand_extracted_skills ? 12 : 6}},[_c('skills-card',{attrs:{"active_skill_group":_vm.active_skill_group,"skills":_vm.cv_job_analysis.searched_cv && !_vm.cv_job_analysis.cv_loading
              ? _vm.cv_job_analysis.searched_cv.upload_response.skills
              : _vm.cv_job_analysis.cv_file_response.upload_response.skills,"expand_view":_vm.expand_extracted_skills,"loading":_vm.cv_job_analysis.cv_loading,"title":"Extracted skills","skill_key":false},on:{"expand_skills_block":function($event){_vm.expand_extracted_skills = !_vm.expand_extracted_skills}}})],1),_c('v-col',{attrs:{"cols":"12","lg":_vm.expand_matched_skills ? 12 : 6}},[_c('skills-card',{attrs:{"active_skill_group":_vm.active_skill_group,"skills":_vm.cv_job_analysis.compare_gap_loading
              ? []
              : _vm.cv_job_analysis.compare_gap_response.matched,"expand_view":_vm.expand_matched_skills,"loading":_vm.cv_job_analysis.compare_gap_loading,"title":"Matched skills"},on:{"expand_skills_block":function($event){_vm.expand_matched_skills = !_vm.expand_matched_skills}}})],1),_c('v-col',{attrs:{"cols":"12","lg":_vm.expand_required_skills ? 12 : 6}},[_c('skills-card',{attrs:{"active_skill_group":_vm.active_skill_group,"skills":_vm.cv_job_analysis.compare_gap_loading
              ? []
              : _vm.cv_job_analysis.compare_gap_response.matched.concat(
                  _vm.cv_job_analysis.compare_gap_response.gap
                ),"expand_view":_vm.expand_required_skills,"loading":_vm.cv_job_analysis.compare_gap_loading,"title":"Skills required for the job"},on:{"expand_skills_block":function($event){_vm.expand_required_skills = !_vm.expand_required_skills}}})],1),_c('v-col',{attrs:{"cols":"12","lg":_vm.expand_skills_gap ? 12 : 6}},[_c('skills-card',{attrs:{"active_skill_group":_vm.active_skill_group,"skills":_vm.cv_job_analysis.compare_gap_loading
              ? []
              : _vm.cv_job_analysis.compare_gap_response.gap,"expand_view":_vm.expand_skills_gap,"loading":_vm.cv_job_analysis.compare_gap_loading,"title":"Skills gap"},on:{"expand_skills_block":function($event){_vm.expand_skills_gap = !_vm.expand_skills_gap}}})],1)],1),(_vm.show_upskill_courses)?_c('upskill-dialog',{on:{"close_dialog":function($event){_vm.show_upskill_courses = false}}}):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }