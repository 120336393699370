





































import Vue from "vue";
export default Vue.extend({
  name: "ProgressBlock",
  props: {
    blockTitle: String,
    progressValue: Number,
    progressColor: String,
    extraClasses: {
      type: String,
      default: ""
    },
    routerLink: {
      type: String,
      default: ""
    },
    size: {
      type: Number,
      default: 50
    },
    width: {
      type: Number,
      default: 4
    }
  }
});
