





















































import Vue from "vue";
import {
  extract_skill_group_styling,
  skills_group
} from "@/utils/skills_group";
export default Vue.extend({
  name: "SkillsCard",
  data: (): Record<string, any> => ({
    extract_skill_group_styling: extract_skill_group_styling,
    skills_group: skills_group
  }),
  props: {
    loading: {
      type: Boolean,
      default: true
    },
    expand_view: {
      type: Boolean,
      default: false
    },
    skills: {
      type: Array,
      required: true
    },
    active_skill_group: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    skill_key: {
      type: Boolean,
      default: true
    }
  }
});
