import { clean_string } from "@/utils/global";

interface SkillsGroup {
  skill_group: string;
  value: string;
  class: string;
  active_class: string;
  icon: string;
  color: string;
  transform_source: (icon: HTMLElement) => HTMLElement;
}
export const skills_group: SkillsGroup[] = [
  {
    skill_group: "Leadership Skills",
    value: "Leadership and Management",
    class: "leadership-management",
    active_class: "active",
    icon: require("@/assets/icons/skills_group/leadership.svg"),
    color: "#FF4066",
    transform_source: (icon: HTMLElement): HTMLElement => {
      for (const node of icon.children) node.setAttribute("fill", "#FF4066");
      return icon;
    }
  },
  {
    skill_group: "Communication/Soft Skills",
    value: "Soft skills",
    class: "soft-skills",
    active_class: "active",
    icon: require("@/assets/icons/skills_group/communication_soft.svg"),
    color: "#FFB815",
    transform_source: (icon: HTMLElement): HTMLElement => {
      for (const node of icon.children) node.setAttribute("fill", "#FFB815");
      return icon;
    }
  },
  {
    skill_group: "IT/Software Skills",
    value: "IT and Software",
    class: "it-software",
    active_class: "active",
    icon: require("@/assets/icons/skills_group/it_software.svg"),
    color: "#33BFFF",
    transform_source: (icon: HTMLElement): HTMLElement => {
      for (const node of icon.children) node.setAttribute("fill", "#33BFFF");
      return icon;
    }
  },

  {
    skill_group: "Domain Specific Skills",
    value: "Hard skills",
    class: "domain-specific",
    active_class: "active",
    icon: require("@/assets/icons/skills_group/domain.svg"),
    color: "#FF8473",
    transform_source: (icon: HTMLElement): HTMLElement => {
      for (const node of icon.children) node.setAttribute("fill", "#FF8473");
      return icon;
    }
  },
  {
    skill_group: "Green Skills",
    value: "Green Skills",
    class: "green-skills",
    active_class: "active",
    icon: require("@/assets/icons/skills_group/green.svg"),
    color: "#3CE17E",
    transform_source: (icon: HTMLElement): HTMLElement => {
      for (const node of icon.children) node.setAttribute("fill", "#3CE17E");
      return icon;
    }
  }
];

/**
 * Return skill group class names
 * @param skill_group
 * @param active_skill_group
 * @return string => class name
 */
export function extract_skill_group_styling(
  skill_group: string,
  active_skill_group: string
): string {
  skill_group = clean_string(skill_group);
  let tmp = skill_group;
  if (skill_group === "soft") tmp = "soft skills";
  if (skill_group === "hardware" || skill_group === "hard") tmp = "hard skills";
  if (skill_group === "green") tmp = "green skills";
  const obj = skills_group.find(
    (val: SkillsGroup) => clean_string(val.value) === tmp
  );
  if (!obj) return "";
  if (clean_string(active_skill_group) === tmp)
    return `${obj.active_class} ${obj.class}`;
  else return obj.class;
}

/**
 * Function to return skills group
 * @return SkillsGroup[]
 */
export function get_skills_group(): SkillsGroup[] {
  return skills_group;
}
