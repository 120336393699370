






























































































































































































































































































































































































































import Vue from "vue";
import ProgressBlock from "@/components/shared/ProgressBlock.vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { format_overall_score } from "@/utils/skill_extractor";
import {
  GET_UPSKILL_COURSES,
  GET_UPSKILL_COURSES_LOADING,
  GETTER_UPSKILL_COURSES
} from "@/store/modules/skill_extractor/constants";
import {
  extract_skill_group_styling,
  skills_group
} from "@/utils/skills_group";
import { clean_string } from "@/utils/global";
import { GET_ERROR, SET_ERROR } from "@/store";
import SkillsGroup from "@/components/shared/SkillsGroup.vue";
export default Vue.extend({
  name: "UpskillDialog",
  data: (): Record<string, any> => ({
    final_upskill_data: [],
    upskill_data: [],
    course_providers: [],
    active_course_provider: "",
    active_course: "", // Hold active course title
    active_course_matched_skills: [],
    active_course_skills: [],
    active_course_score: 0,
    active_skill_group: "",
    active_details_block: "skill", // For navigation b/w skill/course details
    skills_group: skills_group,
    extract_skill_group_styling: extract_skill_group_styling,
    upskill_tabs: 0,
    format_overall_score: format_overall_score,
    show_description: false
  }),
  components: { SkillsGroup, ProgressBlock },
  computed: {
    ...mapGetters("skill_extractor", {
      getter_upskill_courses: GETTER_UPSKILL_COURSES,
      get_upskill_courses_loading: GET_UPSKILL_COURSES_LOADING
    }),
    ...mapGetters({
      get_error: GET_ERROR
    })
  },
  watch: {
    getter_upskill_courses(newValue) {
      // If upskill courses return successfully
      if (newValue && newValue.upskill.length > 0)
        this.process_upskill_courses_data(newValue.upskill);
      else {
        // set global error: if not upskill course found
        this.set_global_error({
          response: {
            data: {
              message: "No Courses Found"
            }
          }
        });
        this.close_upskill_dialog(); // Close upskill dialog
      }
    },
    get_error(value) {
      // If global error: close upskill dialog
      if (value) this.close_upskill_dialog();
    }
  },
  methods: {
    ...mapActions("skill_extractor", {
      get_upskill_courses: GET_UPSKILL_COURSES
    }),
    ...mapMutations({
      set_global_error: SET_ERROR
    }),
    update_skill_group(skill_group: string) {
      if (skill_group === this.active_skill_group) this.active_skill_group = "";
      else this.active_skill_group = skill_group;
    },
    process_upskill_courses_data(upskill_data: any) {
      this.extract_course_providers(upskill_data);
      for (let provider of this.course_providers) {
        const _course_provider = clean_string(provider.course_provider);
        const courses_payload: any = [];
        let total_score = 0;
        for (let course of upskill_data) {
          if (clean_string(course.course_provider) !== _course_provider)
            continue;
          // Extract course data
          const _payload = {
            course_skills: course.course_skills,
            description: course.description,
            link: course.link,
            matched_skills: course.matched_skills,
            score: format_overall_score(course.score),
            title: course.title,
            course_provider: _course_provider
          };
          total_score += course.score;
          courses_payload.push(_payload);
        }
        this.final_upskill_data.push({
          course_provider: _course_provider,
          courses: courses_payload,
          total_score: total_score
        });
        provider.total_score = format_overall_score(total_score);
        const index = this.course_providers.findIndex(
          (value: any) => value.course_provider === provider
        );
        if (index !== -1) this.course_providers[index] = provider;
      }
      this.get_courses();
    },
    extract_course_providers(upskill_data: any) {
      const providers = new Set();
      for (let course of upskill_data)
        providers.add(course.course_provider.toLowerCase().trim());
      for (let provider of providers) {
        let payload = null;
        if (provider === "fifthelement" || provider === "fifth element") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/fifth_element.svg"),
            alt: "Fifth element logo"
          };
          this.course_providers.push(payload);
        } else if (provider === "udemy") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/udemy.svg"),
            alt: "Udemy logo"
          };
          this.course_providers.push(payload);
        } else if (provider === "eyouth") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/eyouth.svg"),
            alt: "Eyouth logo"
          };
          this.course_providers.push(payload);
        } else if (provider === "linkedin") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/linkedin.svg"),
            alt: "Linkedin logo"
          };
          this.course_providers.push(payload);
        } else if (provider === "udacity") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/udacity.svg"),
            alt: "Udacity logo"
          };
          this.course_providers.push(payload);
        } else if (provider === "edx") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/edx.svg"),
            alt: "EDX logo"
          };
          this.course_providers.push(payload);
        } else if (provider === "coursera") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/courseera.svg"),
            alt: "Coursera logo"
          };
          this.course_providers.push(payload);
        } else if (provider === "udemy") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/udemy.svg"),
            alt: "Udemy logo"
          };
          this.course_providers.push(payload);
        } else if (provider === "ischool") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/ischool.png"),
            alt: "iSchool logo"
          };
          this.course_providers.push(payload);
        } else if (provider === "mit") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/mit.svg"),
            alt: "MIT logo"
          };
          this.course_providers.push(payload);
        } else if (provider === "harvard") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/harvard.png"),
            alt: "Harvard logo"
          };
          this.course_providers.push(payload);
        } else if (provider === "oxford") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/oxford.png"),
            alt: "Oxford logo"
          };
          this.course_providers.push(payload);
        } else if (provider === "stanford") {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/stanford.png"),
            alt: "Stanford logo"
          };
          this.course_providers.push(payload);
        } else {
          payload = {
            course_provider: provider,
            logo: require("@/assets/logos/hcms.svg"),
            alt: "HCMS logo"
          };
          this.course_providers.push(payload);
        }
      }
      this.active_course_provider = this.course_providers[0].course_provider;
    },
    extract_course_provider_logo(provider: string): string {
      provider = provider.toLowerCase().trim();
      const obj = this.course_providers.find(
        (val: any) => val.course_provider === provider
      );
      return obj.logo;
    },
    extract_upskill_data(upskill_data: any) {
      this.upskill_tabs = 0;
      this.active_skill_group = "";
      this.active_course = upskill_data.title;
      this.active_course_matched_skills = upskill_data.matched_skills;
      this.active_course_skills = upskill_data.course_skills;
      this.active_course_score = format_overall_score(upskill_data.score);
    },
    get_courses() {
      const data = this.final_upskill_data.find(
        (val: any) => val.course_provider === this.active_course_provider
      );
      this.upskill_data = data.courses;
      this.extract_upskill_data(this.upskill_data[0]);
    },
    close_upskill_dialog() {
      this.upskill_data = [];
      this.course_providers = [];
      this.active_course_provider = "";
      this.active_course = "";
      this.active_course_matched_skills = [];
      this.active_course_skills = [];
      this.active_course_score = 0;
      this.active_skill_group = "";
      this.active_details_block = "skill";
      this.upskill_tabs = 0;
      this.$emit("close_dialog", null);
    },
    change_upskill_group(skill_group: string) {
      if (skill_group === this.active_skill_group) this.active_skill_group = "";
      else this.active_skill_group = skill_group;
    },
    change_course_provider(value: any) {
      this.active_course_provider = value.course_provider;
      this.get_courses();
    }
  }
});
