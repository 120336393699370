













































































































































































































import Vue from "vue";
import { mapActions, mapGetters, mapMutations } from "vuex";
import {
  CV_JOB_ANALYSIS_PAYLOAD,
  FETCH_COMPARE_GAP,
  GET_COMPARE_GAP_SCORE,
  GET_COMPARE_RESPONSE_GAP,
  POST_NEW_JOB,
  SKILL_GAP_LIST,
  UPLOAD_RESUME_AND_FETCH_SKILLS
} from "@/store/modules/cv_job_matching/constants";
import store from "@/store";
import SkillsGroup from "@/components/shared/SkillsGroup.vue";
import SkillsCard from "@/components/skill_extractor/SkillsCard.vue";
import {
  CompareGapResponse,
  SkillGroup
} from "@/interfaces/responses/compare_gap";
import { format_overall_score } from "@/utils/skill_extractor";
import UpskillDialog from "@/components/skill_extractor/UpskillDialog.vue";
import { GET_UPSKILL_COURSES } from "@/store/modules/skill_extractor/constants";
import { UpskillCoursesPayload } from "@/store/modules/skill_extractor/interfaces";

export default Vue.extend({
  name: "CvJobAnalysis",
  components: { UpskillDialog, SkillsCard, SkillsGroup },
  beforeRouteEnter(to, from, next) {
    const analysis =
      store.getters[`cv_job_matching/${CV_JOB_ANALYSIS_PAYLOAD}`];
    if (!analysis.fetching_required) next();
    else if (
      (analysis.cv_file || analysis.searched_cv) &&
      (analysis.job_file || analysis.job_link)
    )
      next();
    else next("/matching/new");
  },
  beforeRouteUpdate(to, from, next) {
    const analysis =
      store.getters[`cv_job_matching/${CV_JOB_ANALYSIS_PAYLOAD}`];
    if (!analysis.fetching_required) next();
    else if (
      (analysis.cv_file || analysis.searched_cv) &&
      (analysis.job_file || analysis.job_link)
    )
      next();
    else next("/matching/new");
  },
  data() {
    return {
      active_skill_group: "",
      expand_extracted_skills: false,
      expand_matched_skills: false,
      expand_required_skills: false,
      expand_skills_gap: false,
      show_upskill_courses: false
    };
  },
  computed: {
    ...mapGetters("cv_job_matching", {
      cv_job_analysis: CV_JOB_ANALYSIS_PAYLOAD,
      compare_gap_score: GET_COMPARE_GAP_SCORE,
      compare_gap_list: GET_COMPARE_RESPONSE_GAP
    })
  },
  async mounted() {
    if (!this.cv_job_analysis.fetching_required) return;
    // If user selected from searched cv
    if (this.cv_job_analysis.searched_cv) {
      const analysis = this.cv_job_analysis;
      analysis.cv_loading = false;
      this.set_cv_job_analysis(analysis);
    }
    // If user uploaded cv file
    else if (
      this.cv_job_analysis.cv_file &&
      !this.cv_job_analysis.cv_file_response
    ) {
      const analysis = this.cv_job_analysis;
      // Call backend API
      const payload = new FormData();
      payload.append("cvFile", this.cv_job_analysis.cv_file);
      const extracted_skills = await this.upload_resume_fetch_skills(payload);
      // If failed to get extracted skills
      if (!extracted_skills) {
        await this.$router.push("/matching/new");
        return;
      }
      // If cv skills fetched successfully
      analysis.cv_file_response = extracted_skills;
      analysis.cv_loading = false;
      this.set_cv_job_analysis(analysis);
    }

    // If user added a job link
    if (this.cv_job_analysis.job_link && !this.cv_job_analysis.job_response) {
      const analysis = this.cv_job_analysis;
      const payload = new FormData();
      payload.append("type", "url");
      payload.append("url", this.cv_job_analysis.job_link);
      const response = await this.post_new_job(payload);
      if (!response) {
        await this.$router.push("/matching/new");
        return;
      }
      analysis.job_loading = false;
      analysis.job_response = response;
      this.set_cv_job_analysis(analysis);
      // If user added job file
    } else if (
      this.cv_job_analysis.job_file &&
      !this.cv_job_analysis.job_response
    ) {
      const analysis = this.cv_job_analysis;
      const payload = new FormData();
      payload.append("type", "file");
      payload.append("file", this.cv_job_analysis.job_file);
      const response = await this.post_new_job(payload);
      if (!response) {
        await this.$router.push("/matching/new");
        return;
      }
      analysis.job_loading = false;
      analysis.job_response = response;
      this.set_cv_job_analysis(analysis);
    }

    if (
      this.cv_job_analysis.job_response &&
      (this.cv_job_analysis.cv_file_response ||
        this.cv_job_analysis.searched_cv) &&
      !this.cv_job_analysis.compare_gap_response
    ) {
      if (!this.cv_job_analysis.job_file) {
        const job = this.cv_job_analysis.job_response.jid;
        const cv = this.cv_job_analysis.searched_cv
          ? this.cv_job_analysis.searched_cv.id
          : this.cv_job_analysis.cv_file_response.id;
        const payload = {
          cv,
          job
        };
        const compare_gap: CompareGapResponse | null =
          await this.fetch_compare_gap(payload);
        if (!compare_gap) {
          await this.$router.push("/matching/new");
          return;
        }
        const analysis = this.cv_job_analysis;
        analysis.compare_gap_response = compare_gap;
        analysis.compare_gap_loading = false;
        this.set_cv_job_analysis(analysis);
      } else {
        const cv = this.cv_job_analysis.searched_cv
          ? this.cv_job_analysis.searched_cv
          : this.cv_job_analysis.cv_file_response;
        const _ref_skills = cv.upload_response.skills;
        const compare_skills = _ref_skills.map(
          (skill: SkillGroup) => skill.ref_skill
        );
        const ref_skills = this.cv_job_analysis.job_response.skills_list.map(
          (skill: SkillGroup) => skill.ref_skill
        );
        const payload = {
          ref_skills,
          compare_skills,
          cv: cv.id
        };
        const compare_gap = await this.fetch_skill_gap_list(payload);
        if (!compare_gap) {
          await this.$router.push("/matching/new");
          return;
        }
        const analysis = this.cv_job_analysis;
        analysis.compare_gap_response = compare_gap;
        analysis.compare_gap_loading = false;
        this.set_cv_job_analysis(analysis);
      }
    }
  },
  methods: {
    format_overall_score,
    ...mapActions("skill_extractor", {
      get_upskill_courses: GET_UPSKILL_COURSES
    }),
    ...mapMutations("cv_job_matching", {
      set_cv_job_analysis: CV_JOB_ANALYSIS_PAYLOAD
    }),
    ...mapActions("cv_job_matching", {
      upload_resume_fetch_skills: UPLOAD_RESUME_AND_FETCH_SKILLS,
      post_new_job: POST_NEW_JOB,
      fetch_compare_gap: FETCH_COMPARE_GAP,
      fetch_skill_gap_list: SKILL_GAP_LIST
    }),
    update_skill_group(skill_group: string) {
      if (skill_group === this.active_skill_group) this.active_skill_group = "";
      else this.active_skill_group = skill_group;
    },
    async open_upskill_dialog() {
      let job_gap: Array<string> = [];
      const compare_gap = this.compare_gap_list;
      if (!compare_gap) return;

      compare_gap.forEach((val: SkillGroup) => {
        job_gap.push(val.skill);
      });
      const cv_id = this.cv_job_analysis.searched_cv
        ? this.cv_job_analysis.searched_cv.upload_response_id
        : this.cv_job_analysis.cv_file_response.upload_response_id;
      const payload: UpskillCoursesPayload = {
        response_id: cv_id,
        gap_list: job_gap
      };
      if (
        this.cv_job_analysis.job_response &&
        this.cv_job_analysis.job_response.jid
      )
        payload.job_id = this.cv_job_analysis.job_response.jid;
      else if (this.cv_job_analysis.job_response) {
        const skills: Array<string> = [];
        this.cv_job_analysis.job_response.skills_list.forEach(
          (val: SkillGroup) => {
            skills.push(val.ref_skill);
          }
        );
        payload.skills = skills;
      } else {
        const match = this.cv_job_analysis.compare_gap_response.matched.map(
          (skill: SkillGroup) => skill.skill
        );
        const gap = this.cv_job_analysis.compare_gap_response.gap.map(
          (skill: SkillGroup) => skill.skill
        );
        payload.skills = match.concat(gap);
      }
      this.show_upskill_courses = true;
      await this.get_upskill_courses(payload);
    },
    async match_again() {
      const payload = {
        cv_loading: false,
        job_loading: false,
        compare_gap_loading: false,
        cv_file: null,
        job_file: null,
        job_link: null,
        searched_cv: null,
        cv_file_response: null,
        job_response: null,
        compare_gap_response: null
      };
      this.set_cv_job_analysis(payload);
      await this.$router.push("/matching/new");
    }
  }
});
