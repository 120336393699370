export function clean_string(value: string): string {
  return value.trim().toLowerCase();
}

/**
 * Return Regex for linkedin URL validation
 * @return Regex for linkedin URl validation. You can use .test function to validate string
 */
export function get_linkedin_regex(): RegExp {
  return /^(?:https?:\/\/)?(?:www\.)?(linkedin)\.(com)\/(in|pub|public-profile\/in|public-profile\/pub|company|jobs)\/(?:(?:\w\.)*#!\/)?(?:pages\/)?(?:[\w\-.]*\/)*([\w\-.]*)/;
}

/**
 * Format file name. Remove date from file name
 * @param value: string value stored in DB as uploaded_file_name
 * @return string: formatted file name
 */
export function format_file_name(value: string): string {
  return value.split("-").slice(1).join(" ");
}
